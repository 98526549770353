<template>
  <b-sidebar
    id="add-edit-lesson-sidebar"
    :visible="isAddEditLessonSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    width="1200px"
    shadow
    backdrop
    no-header
    right
    :no-close-on-backdrop="true"
    @hidden="resetForm"
    @shown="onShow"
    @change="sideBarChange"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ lesson > -1 ? 'Изменить тема' : 'Добавить новая тема' }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2 add-lesson-form"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Наименование урок -->
          <validation-provider
            #default="{ errors }"
            name="Наименование тема"
            rules="required"
          >
            <b-form-group
              label="Наименование тема"
              label-for="title"
            >
              <b-form-input
                id="title"
                v-model="title"
                autofocus
                :state="errors.length > 0 ? false:null"
                trim
                placeholder=""
                autocomplete="off"
              />

              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Тип урок -->
          <validation-provider
            #default="{ errors }"
            name="тип тема"
            rules="required"
          >
            <b-form-group
              label="Тип"
              label-for="type"
              label-class="mb-1"
            >
              <b-form-radio-group
                id="type"
                v-model="type"
                :options="typeOptions"
                value="1"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Текст урок -->
          <b-form-group
            v-if="type===1"
            label="Тект"
            label-for="text"
          >
            <quill-editor
              id="text"
              v-model="text"
              :options="editorOption"
            />
          </b-form-group>

          <!-- файл урок -->
          <b-form-group
            v-if="type===2"
            label="Файл"
            label-for="file"
          >
            <b-form-file
              id="file"
              v-model="file"
              browse-text="Обзор..."
              accept="application/pdf, .docx, .doc, .ppt, .pptx, .html"
              :placeholder="path ? path : 'Выберите файл или перетащите его сюда...'"
              drop-placeholder="Скиньте файл сюда..."
            />
          </b-form-group>
          <!-- Form Actions -->
          <div class="d-flex mt-2 add-lessons-form-btn">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Сохранить
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Отменить
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormRadioGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormFile,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import ru from 'vee-validate/dist/locale/ru.json'
import {
  required, alphaNum, email, regex,
} from '@validations'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import ImageResize from 'quill-image-resize-vue'
import * as Quill from 'quill'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { ref } from '@vue/composition-api'

Quill.register('modules/imageResize', ImageResize)

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormRadioGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormFile,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    quillEditor,
    ImageResize,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddEditLessonSidebarActive',
    event: 'update:is-add-edit-lesson-sidebar-active',
  },
  props: {
    isAddEditLessonSidebarActive: {
      type: Boolean,
      required: true,
    },
    module: {
      type: Number,
      required: true,
    },
    head: {
      type: Number,
      required: true,
    },
    lesson: {
      type: Number,
      required: true,
    },
    modules: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      regex,
      title: '',
      text: '',
      type: 1,
      file: null,
      path: null,
      typeOptions: [
        {
          text: 'Текстовый редактор',
          value: 1,
        },
        {
          text: 'Файл',
          value: 2,
        },
      ],
      editorOption: {
        theme: 'snow',
        bounds: '#scrolling-container',
        scrollingContainer: '#scrolling-container',
        placeholder: 'Описание',
        modules: {
          imageResize: {},
          toolbar: {
            container: [
              [{ size: ['6px', '8px', '10px', '12px', '14px', '16px', '18px', '20px', '24px', '30px', '32px', '36px'] }],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              ['bold', 'italic', 'underline', 'strike'],
              [{ color: [] }, { background: [] }],
              [{ script: 'super' }, { script: 'sub' }],
              [{ header: '1' }, { header: '2' }, 'blockquote', 'code-block'],
              [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
              [{ direction: 'rtl' }, { align: [] }],
              ['link', 'image', 'video', 'formula'],
              ['clean'],
            ],
          },
        },
      },

    }
  },
  setup() {
    const buildFormData = (formData, data, parentKey) => {
      if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data)
          .forEach(key => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key)
          })
      } else {
        const value = data == null ? '' : data

        formData.append(parentKey, value)
      }
    }
    return {
      buildFormData,
    }
  },
  created() {
    localize('ru', ru)
  },
  methods: {
    sideBarChange(val) {
      this.$emit('update:is-add-edit-lesson-sidebar-active', val)
    },
    onShow() {
      if (this.module > -1) {
        if (this.head > -1 && this.lesson > -1) {
          this.title = this.modules[this.module].childs[this.head].lessons[this.lesson].name
          this.text = this.modules[this.module].childs[this.head].lessons[this.lesson].value
          this.path = this.modules[this.module].childs[this.head].lessons[this.lesson].path
          this.file = null
          this.type = Number(this.modules[this.module].childs[this.head].lessons[this.lesson].typeLesson)
        } else if (this.head <= -1 && this.lesson > -1) {
          this.title = this.modules[this.module].childs[this.lesson].name
          this.text = this.modules[this.module].childs[this.lesson].value
          this.path = this.modules[this.module].childs[this.lesson].path
          this.file = null
          this.type = Number(this.modules[this.module].childs[this.lesson].typeLesson)
        }
      }
    },
    onSubmit() {
      if (this.module > -1) {
        if (this.head > -1 && this.lesson > -1) {
          const data = {
            course_module_id: this.modules[this.module].id,
            chapter_id: this.modules[this.module].childs[this.head].id,
            name: this.title,
            file: this.file,
            value: this.text,
            type: this.type,
          }
          const formData = new FormData()

          this.buildFormData(formData, data)
          this.$http.post(`https://edu.startupchoikhona.tj/backend/api/lesson/update/${this.modules[this.module].childs[this.head].lessons[this.lesson].id}`, formData)
            .then(response => {
              this.modules[this.module].childs[this.head].lessons[this.lesson].name = this.title
              this.modules[this.module].childs[this.head].lessons[this.lesson].typeLesson = Number(this.type)

              if (this.type === 1) {
                this.modules[this.module].childs[this.head].lessons[this.lesson].value = this.text
                this.modules[this.module].childs[this.head].lessons[this.lesson].path = null
                this.modules[this.module].childs[this.head].lessons[this.lesson].file = null
              } else if (this.type === 2) {
                this.modules[this.module].childs[this.head].lessons[this.lesson].value = ''
                this.modules[this.module].childs[this.head].lessons[this.lesson].path = this.file ? this.file.name : ''
                this.modules[this.module].childs[this.head].lessons[this.lesson].file = this.file
              }
              this.$emit('update:is-add-edit-lesson-sidebar-active', false)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Успешно',
                  icon: 'CheckIcon',
                  variant: 'success',
                  text: response.data.message,
                },
              })
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Ошибка',
                  icon: 'XCircleIcon',
                  variant: 'danger',
                  text: error.response.data.message,
                },
              })
            })
        } else if (this.head <= -1 && this.lesson > -1) {
          const data = {
            course_module_id: this.modules[this.module].id,
            name: this.title,
            file: this.file,
            value: this.text,
            type: this.type,
          }
          const formData = new FormData()

          this.buildFormData(formData, data)
          this.$http.post(`https://edu.startupchoikhona.tj/backend/api/lesson/update/${this.modules[this.module].childs[this.lesson].id}`, formData)
            .then(response => {
              this.modules[this.module].childs[this.lesson].name = this.title
              this.modules[this.module].childs[this.lesson].typeLesson = Number(this.type)
              if (this.type === 1) {
                this.modules[this.module].childs[this.lesson].value = this.text
                this.modules[this.module].childs[this.lesson].path = null
                this.modules[this.module].childs[this.lesson].file = null
              } else if (this.type === 2) {
                this.modules[this.module].childs[this.lesson].value = ''
                this.modules[this.module].childs[this.lesson].path = this.file ? this.file.name : (this.modules[this.module].childs[this.lesson].path ? this.modules[this.module].childs[this.lesson].path : '')
                this.modules[this.module].childs[this.lesson].file = this.file
              }
              this.$emit('update:is-add-edit-lesson-sidebar-active', false)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Успешно',
                  icon: 'CheckIcon',
                  variant: 'success',
                  text: response.data.message,
                },
              })
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Ошибка',
                  icon: 'XCircleIcon',
                  variant: 'danger',
                  text: error.response.data.message,
                },
              })
            })
        } else if (this.head > -1 && this.lesson <= -1) {
          const data = {
            course_module_id: this.modules[this.module].id,
            chapter_id: this.modules[this.module].childs[this.head].id,
            name: this.title,
            file: this.file,
            value: this.text,
            type: this.type,
          }
          const formData = new FormData()

          this.buildFormData(formData, data)
          this.$http.post('https://edu.startupchoikhona.tj/backend/api/lesson/create', formData)
            .then(response => {
              this.modules[this.module].childs[this.head].lessons.push({
                name: this.title,
                lessons: [],
                type: 2,
                typeLesson: this.type,
                text: this.text,
                path: this.file ? this.file.name : '',
                file: this.file,
              })
              this.$emit('update:is-add-edit-lesson-sidebar-active', false)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Успешно',
                  icon: 'CheckIcon',
                  variant: 'success',
                  text: response.data.message,
                },
              })
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Ошибка',
                  icon: 'XCircleIcon',
                  variant: 'danger',
                  text: error.response.data.message,
                },
              })
            })
        } else if (this.head <= -1 && this.lesson <= -1) {
          const data = {
            course_module_id: this.modules[this.module].id,
            name: this.title,
            file: this.file,
            value: this.text,
            type: this.type,
          }
          const formData = new FormData()

          this.buildFormData(formData, data)
          this.$http.post('https://edu.startupchoikhona.tj/backend/api/lesson/create', formData)
            .then(response => {
              this.modules[this.module].childs.push({
                name: this.title,
                childs: [],
                type: 2,
                typeLesson: this.type,
                text: this.text,
                path: this.file ? this.file.name : '',
                file: this.file,
              })
              this.$emit('update:is-add-edit-lesson-sidebar-active', false)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Успешно',
                  icon: 'CheckIcon',
                  variant: 'success',
                  text: response.data.message,
                },
              })
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Ошибка',
                  icon: 'XCircleIcon',
                  variant: 'danger',
                  text: error.response.data.message,
                },
              })
            })
        }
      }
    },
    resetForm() {
      this.title = ''
      this.text = ''
      this.type = 1
      this.file = null
      this.$refs.refFormObserver.reset()
    },
  },
}
</script>

<style lang="scss">
#text .ql-editor {
  min-height: 300px;
}

.add-lessons-form-btn {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 20px;
  left: 0;
  background-color: #fff;
}

.add-lesson-form {
  padding-bottom: 6rem !important;
}
</style>
