<template>
  <b-sidebar
    id="add-edit-head-sidebar"
    :visible="isAddEditHeadSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    :no-close-on-backdrop="true"
    @hidden="resetForm"
    @shown="title = head > -1 && module > -1 ? modules[module].childs[head].name : ''"
    @change="sideBarChange"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ head > -1 && module > -1 ? 'Изменить раздел' : 'Добавить новый раздел' }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Наименование модуля -->
          <validation-provider
            #default="{ errors }"
            name="Наименование раздела"
            rules="required"
          >
            <b-form-group
              label="Наименование раздела"
              label-for="title"
            >
              <b-form-input
                id="title"
                v-model="title"
                autofocus
                :state="errors.length > 0 ? false:null"
                trim
                placeholder=""
                autocomplete="off"
              />

              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Сохранить
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Отменить
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import ru from 'vee-validate/dist/locale/ru.json'
import {
  required, alphaNum, email, regex,
} from '@validations'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddEditHeadSidebarActive',
    event: 'update:is-add-edit-head-sidebar-active',
  },
  props: {
    isAddEditHeadSidebarActive: {
      type: Boolean,
      required: true,
    },
    course: {
      type: [Number, String],
      required: true,
    },
    module: {
      type: Number,
      required: true,
    },
    head: {
      type: Number,
      required: true,
    },
    modules: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      regex,
      title: '',
    }
  },
  created() {
    localize('ru', ru)
  },
  methods: {
    sideBarChange(val) {
      this.$emit('update:is-add-edit-head-sidebar-active', val)
    },
    onSubmit() {
      if (this.module > -1) {
        if (this.head > -1) {
          this.$http.post(`https://edu.startupchoikhona.tj/backend/api/chapter/update/${this.modules[this.module].childs[this.head].id}`, {
            course_module_id: this.modules[this.module].id,
            name: this.title,
            status: 1,
          })
            .then(response => {
              this.modules[this.module].childs[this.head].name = this.title
              this.$emit('update:is-add-edit-head-sidebar-active', false)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Успешно',
                  icon: 'CheckIcon',
                  variant: 'success',
                  text: response.data.message,
                },
              })
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Ошибка',
                  icon: 'XCircleIcon',
                  variant: 'danger',
                  text: error.response.data.message,
                },
              })
            })
        } else {
          this.$http.post('https://edu.startupchoikhona.tj/backend/api/chapter/create', {
            course_module_id: this.modules[this.module].id,
            name: this.title,
            status: 1,
          })
            .then(response => {
              this.modules[this.module].childs.push({
                name: this.title,
                type: 1,
                typeText: '',
                text: '',
                path: '',
                lessons: [],
                tests: [],
              })
              this.$emit('update:is-add-edit-head-sidebar-active', false)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Успешно',
                  icon: 'CheckIcon',
                  variant: 'success',
                  text: response.data.message,
                },
              })
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Ошибка',
                  icon: 'XCircleIcon',
                  variant: 'danger',
                  text: error.response.data.message,
                },
              })
            })
        }
      }
    },
    resetForm() {
      this.title = ''
      this.$refs.refFormObserver.reset()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-edit-module-sidebar {
  //.vs__dropdown-menu {
  //  max-height: 200px !important;
  //}
}
</style>
