<template>
  <b-card
    no-body
    class="shadow-none"
    :class="{'open': visible}"
    @mouseenter="collapseOpen"
    @mouseleave="collapseClose"
  >
    <b-card-header
      class="p-1"
      :class="{'collapsed': !visible}"
      :aria-expanded="visible ? 'true' : 'false'"
      :aria-controls="collapseItemID"
      role="tab"
      :data-toggle="editAction || deleteAction || previewAction ? '' : 'collapse'"
      @click="!editAction && !deleteAction && !previewAction ? updateVisible(!visible) : ''"
    >
      <slot name="header">
        <div
          v-if="editAction || deleteAction || previewAction"
          class="d-flex align-items-center justify-content-between w-100"
        >
          <b-media
            class="w-100"
            no-body
            @click="updateVisible(!visible)"
            v-if="!previewAction"
          >
            <div class="d-inline-flex m-1 align-items-center">
              <b-media-aside>
                <b-avatar :text="(title.replace( /(<([^>]+)>)/ig, '')).slice(0,2)" />
              </b-media-aside>
              <b-media-body>
                <h6 class="mb-0 transaction-title">
                  <span v-html="title" />
                </h6>
              </b-media-body>
            </div>
          </b-media>
          <span class="lead collapse-title" v-if="previewAction">{{ title }}</span>
          <div class="font-weight-bolder d-inline-flex">
            <span
              v-if="editAction"
              class="mr-50 text-success cursor-pointer"
              @click="showAddEditSidebar(idItem)"
            >
              <feather-icon
                icon="EditIcon"
                size="18"
              />
            </span>
            <span
              v-if="deleteAction"
              class="text-danger cursor-pointer"
              @click="deleteRow"
            >
              <feather-icon
                icon="Trash2Icon"
                size="18"
              />
            </span>
            <span
              v-if="previewAction"
              class="text-danger cursor-pointer"
              @click="previewActionClick"
            >
              <feather-icon
                icon="PlayIcon"
                size="18"
              />
            </span>
            <span
              v-if="!previewAction"
              class="text-danger cursor-pointer"
              @click="updateVisible(!visible)"
            >
              <feather-icon
                :icon="visible ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                size="18"
              />
            </span>
          </div>
        </div>
        <div v-else>
          <span class="lead collapse-title">{{ title }}</span>
        </div>
      </slot>
    </b-card-header>

    <b-collapse
      :id="collapseItemID"
      v-model="visible"
      :accordion="accordion"
      role="tabpanel"
    >
      <b-card-body>
        <slot />
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCollapse,
  BAvatar,
  BCardText,
  BMediaBody,
  BMedia,
  BMediaAside,
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
    BAvatar,
    BCardText,
    BMediaBody,
    BMedia,
    BMediaAside,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    editAction: {
      type: Boolean,
      default: false,
    },
    deleteAction: {
      type: Boolean,
      default: false,
    },
    previewAction: {
      type: Boolean,
      default: false,
    },
    previewActionLink: {
      type: String,
      default: '',
    },
    idItem: {
      type: [Number, Array],
      default: 0,
    },
  },
  data() {
    return {
      visible: false,
      collapseItemID: '',
      openOnHover: this.$parent.hover,
    }
  },
  computed: {
    accordion() {
      return this.$parent.accordion ? `accordion-${this.$parent.collapseID}` : (this.$parent.$parent.$parent.accordion ? `accordion-${this.$parent.$parent.$parent.collapseID}` : null)
    },
  },
  created() {
    this.collapseItemID = uuidv4()
    this.visible = this.isVisible
  },
  methods: {
    updateVisible(val = true) {
      this.visible = val
      this.$emit('visible', val)
    },
    collapseOpen() {
      if (this.openOnHover) this.updateVisible(true)
    },
    collapseClose() {
      if (this.openOnHover) this.updateVisible(false)
    },
    showAddEditSidebar() {
      if ((typeof this.idItem) === 'number') {
        this.$emit('edit-action-click', this.idItem)
      } else if ((typeof this.idItem) === 'object' && this.idItem.length === 2) {
        this.$emit('edit-action-click', this.idItem[0], this.idItem[1])
      } else if ((typeof this.idItem) === 'object' && this.idItem.length === 3) {
        this.$emit('edit-action-click', this.idItem[0], this.idItem[1], this.idItem[2])
      } else if ((typeof this.idItem) === 'object' && this.idItem.length === 4) {
        this.$emit('edit-action-click', this.idItem[0], this.idItem[1], this.idItem[2], this.idItem[3])
      }
    },
    deleteRow() {
      if ((typeof this.idItem) === 'number') {
        this.$emit('delete-action-click', this.idItem)
      } else if ((typeof this.idItem) === 'object' && this.idItem.length === 2) {
        this.$emit('delete-action-click', this.idItem[0], this.idItem[1])
      } else if ((typeof this.idItem) === 'object' && this.idItem.length === 3) {
        this.$emit('delete-action-click', this.idItem[0], this.idItem[1], this.idItem[2])
      } else if ((typeof this.idItem) === 'object' && this.idItem.length === 4) {
        this.$emit('delete-action-click', this.idItem[0], this.idItem[1], this.idItem[2], this.idItem[3])
      }
    },
    previewActionClick() {
      this.$router.replace(this.previewActionLink)
        .then(() => {
        })
        .catch(() => {
        })
    },
  },
}
</script>
