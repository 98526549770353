<template>
  <div>
    <course-module-add-new
      :is-add-edit-module-sidebar-active.sync="isAddEditModuleSidebarActive"
      :module="selectedModule"
      :course="courseId"
      :modules.sync="modules"
    />
    <course-head-add-new
      :is-add-edit-head-sidebar-active.sync="isAddEditHeadSidebarActive"
      :module="selectedModule"
      :head="selectedHead"
      :course="courseId"
      :modules.sync="modules"
    />
    <course-lesson-add-new
      :is-add-edit-lesson-sidebar-active.sync="isAddEditLessonSidebarActive"
      :module="selectedModule"
      :head="selectedHead"
      :lesson="selectedLesson"
      :modules.sync="modules"
    />
    <course-test-add-new
      :is-add-edit-test-sidebar-active.sync="isAddEditTestSidebarActive"
      :module="selectedModule"
      :head="selectedHead"
      :test="selectedTest"
      :modules.sync="modules"
    />
    <b-row>
      <b-col
        cols="9"
        sm="12"
        md="9"
        class="mb-2"
      >
        <h5 class="mb-0">
          Информация о модулях
        </h5>
        <small class="text-muted">Введите информацию о вашей модуле.</small>
      </b-col>
      <b-col
        class="mb-2"
        cols="12"
        sm="12"
        md="3"
        lg="3"
        xl="3"
      >
        <b-button
          variant="light"
          class="w-100"
          @click="showAddEditModuleSidebar(-1)"
        >
          <span class="text-nowrap">Добавить модуль</span>
        </b-button>
      </b-col>
      <b-col md="12">
        <app-collapse
          accordion
          :type="'border'"
        >
          <draggable
            v-model="modules"
            class="list-group list-group-flush cursor-move"
            tag="div"
          >
            <transition-group
              type="transition"
              name="flip-list"
            >
              <app-collapse-item
                v-for="(listItem, index) in modules"
                :key="index"
                :title="listItem.name"
                :id-item="index"
                :delete-action="true"
                :edit-action="true"
                @edit-action-click="showAddEditModuleSidebar"
                @delete-action-click="deleteModule"
              >
                <b-row class="justify-content-end">

                  <b-col
                    sm="12"
                    xl="3"
                    lg="4"
                    md="4"
                    class="mb-1"
                  >
                    <b-button
                      variant="light"
                      class="w-100"
                      @click="showAddEditHeadSidebar(index, -1)"
                    >
                      <span class="text-nowrap">Добавить раздел</span>
                    </b-button>
                  </b-col>
                  <b-col
                    sm="12"
                    xl="3"
                    lg="4"
                    md="4"
                    class="mb-1"
                  >
                    <b-button
                      variant="light"
                      class="w-100"
                      @click="callLessonOrTest(index, -1, -1, 'lesson')"
                    >
                      <span class="text-nowrap">Добавить тему</span>
                    </b-button>
                  </b-col>
                  <b-col
                    sm="12"
                    xl="3"
                    lg="4"
                    md="4"
                    class="mb-1"
                  >
                    <b-button
                      variant="light"
                      class="w-100"
                      @click="callLessonOrTest(index, -1, -1, 'test')"
                    >
                      <span class="text-nowrap">Добавить тест</span>
                    </b-button>
                  </b-col>
                </b-row>
                <app-collapse
                  v-if="listItem.childs && listItem.childs.length > 0"
                  accordion
                  :type="'border'"
                >
                  <draggable
                    v-model="listItem.childs"
                    class="list-group list-group-flush cursor-move"
                    tag="div"
                  >
                    <transition-group
                      type="transition"
                      name="flip-list"
                    >
                      <app-collapse-item
                        v-for="(listItemChildItem, indexChild) in listItem.childs"
                        :key="indexChild"
                        :title="listItemChildItem.name"
                        :id-item="[index, indexChild]"
                        :delete-action="true"
                        :edit-action="true"
                        @edit-action-click="showAddEditHeadSidebar"
                        @delete-action-click="deleteHead"
                      >
                        <b-row
                          v-if="listItemChildItem.type === 1"
                          class="justify-content-end"
                        >
                          <b-col
                            sm="12"
                            xl="3"
                            lg="4"
                            md="4"
                            class="mb-1"
                          >
                            <b-button
                              variant="light"
                              class="w-100"
                              @click="callLessonOrTest(index, indexChild, -1, 'lesson')"
                            >
                              <feather-icon
                                icon="PlusIcon"
                                class="mr-50"
                                size="18"
                              />
                              <span class="text-nowrap">Добавить тему</span>
                            </b-button>
                          </b-col>
                          <b-col
                            sm="12"
                            xl="3"
                            lg="4"
                            md="4"
                            class="mb-1"
                          >
                            <b-button
                              variant="light"
                              class="w-100"
                              @click="callLessonOrTest(index, indexChild, -1, 'test')"
                            >
                              <span class="text-nowrap">Добавить тест</span>
                            </b-button>
                          </b-col>
                        </b-row>
                        <b-row v-else-if="listItemChildItem.type === 2">
                          <b-col
                            sm="12"
                            xl="12"
                            lg="12"
                            md="12"
                            class=""
                            v-html="listItemChildItem.text"
                          />
                        </b-row>

                        <app-collapse
                          v-if="listItemChildItem.lessons && listItemChildItem.lessons.length > 0"
                          accordion
                          :type="'border'"
                        >
                          <draggable
                            v-model="listItemChildItem.lessons"
                            class="list-group list-group-flush cursor-move"
                            tag="div"
                          >
                            <transition-group
                              type="transition"
                              name="flip-list"
                            >
                              <app-collapse-item
                                v-for="(listItemChild2Item, indexChild2) in listItemChildItem.lessons"
                                :key="indexChild2"
                                :title="listItemChild2Item.name"
                                :id-item="[index, indexChild, indexChild2]"
                                :delete-action="true"
                                :edit-action="true"
                                @edit-action-click="showAddEditLessonSidebar"
                                @delete-action-click="deleteLesson"
                              />
                            </transition-group>
                          </draggable>
                        </app-collapse>
                        <app-collapse
                          v-if="listItemChildItem.tests && listItemChildItem.tests.length > 0"
                          accordion
                          :type="'border'"
                        >
                          <draggable
                            v-model="listItemChildItem.tests"
                            class="list-group list-group-flush cursor-move"
                            tag="div"
                          >
                            <transition-group
                              type="transition"
                              name="flip-list"
                            >
                              <app-collapse-item
                                v-for="(listItemChild2Item, indexChild2) in listItemChildItem.tests"
                                :key="indexChild2"
                                :title="listItemChild2Item.name"
                                :id-item="[index, indexChild, indexChild2]"
                                :delete-action="true"
                                :edit-action="true"
                                @edit-action-click="showAddEditTestSidebar"
                                @delete-action-click="deleteTest"
                              />
                            </transition-group>
                          </draggable>
                        </app-collapse>
                      </app-collapse-item>
                    </transition-group>
                  </draggable>
                </app-collapse>

              </app-collapse-item>
            </transition-group>
          </draggable>
        </app-collapse>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BListGroupItem,
  BAvatar,
  BCardText,
  BMediaBody,
  BMedia,
  BMediaAside,
  BButton,
} from 'bootstrap-vue'
import draggable from 'vuedraggable'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { ref } from '@vue/composition-api'
import CourseModuleAddNew from './partials/CourseModuleAddNew.vue'
import CourseHeadAddNew from './partials/CourseHeadAddNew.vue'
import CourseLessonAddNew from './partials/CourseLessonAddNew.vue'
import CourseTestAddNew from './partials/CourseTestAddNew.vue'

export default {
  components: {
    draggable,
    BRow,
    BCol,
    BListGroupItem,
    BAvatar,
    BCardText,
    BMediaBody,
    BMedia,
    BMediaAside,
    BButton,
    AppCollapse,
    AppCollapseItem,
    CourseModuleAddNew,
    CourseHeadAddNew,
    CourseLessonAddNew,
    CourseTestAddNew,
  },
  props: {
    courseId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      selectedModule: -1,
      selectedHead: -1,
      selectedLesson: -1,
      selectedTest: -1,
      modules: [],
    }
  },
  setup() {
    const isAddEditModuleSidebarActive = ref(false)
    const isAddEditHeadSidebarActive = ref(false)
    const isAddEditLessonSidebarActive = ref(false)
    const isAddEditTestSidebarActive = ref(false)
    const buildFormData = (formData, data, parentKey) => {
      if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
          buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key)
        })
      } else {
        const value = data == null ? '' : data

        formData.append(parentKey, value)
      }
    }
    return {
      isAddEditModuleSidebarActive,
      isAddEditHeadSidebarActive,
      isAddEditLessonSidebarActive,
      isAddEditTestSidebarActive,
      buildFormData,
    }
  },
  created() {
    this.view()
  },
  methods: {
    view() {
      this.$http.post(`https://edu.startupchoikhona.tj/backend/api/course/${this.courseId}/modules`, {})
        .then(response => {
          const {
            data,
          } = response
          this.modules = data
        }).catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ошибка',
              icon: 'XCircleIcon',
              variant: 'danger',
              text: error.data.message,
            },
          })
        })
    },
    deleteTest(module, head, test) {

      this.$http.post(`https://edu.startupchoikhona.tj/backend/api/test/delete/${this.modules[module].childs[head].tests[test].id}`, {
      })
        .then(response => {
          this.modules[module].childs[head].tests.splice(test, 1)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Успешно',
              icon: 'CheckIcon',
              variant: 'success',
              text: response.data.message,
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ошибка',
              icon: 'XCircleIcon',
              variant: 'danger',
              text: error.response.data.message,
            },
          })
        })
    },
    showAddEditModuleSidebar(module) {
      this.isAddEditModuleSidebarActive = true
      this.selectedModule = module
    },
    deleteModule(index) {
      this.$http.post(`https://edu.startupchoikhona.tj/backend/api/course-module/delete/${this.modules[index].id}`, {
      })
        .then(response => {
          this.modules.splice(index, 1)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Успешно',
              icon: 'CheckIcon',
              variant: 'success',
              text: response.data.message,
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ошибка',
              icon: 'XCircleIcon',
              variant: 'danger',
              text: error.response.data.message,
            },
          })
        })
    },
    showAddEditHeadSidebar(module, head) {
      if (head > -1) {
        const typeChild = this.modules[module].childs[head].type
        if (typeChild === 1) {
          this.isAddEditHeadSidebarActive = true
          this.selectedModule = module
          this.selectedHead = head
        } else if (typeChild === 2) {
          this.isAddEditLessonSidebarActive = true
          this.selectedModule = module
          this.selectedLesson = head
        } else if (typeChild === 3) {
          this.isAddEditTestSidebarActive = true
          this.selectedModule = module
          this.selectedTest = head
        }
      } else {
        this.isAddEditHeadSidebarActive = true
        this.selectedModule = module
        this.selectedHead = head
      }
    },
    deleteHead(module, head) {
      if (head > -1) {
        const typeChild = this.modules[module].childs[head].type
        if (typeChild === 1) {
          this.$http.post(`https://edu.startupchoikhona.tj/backend/api/chapter/delete/${this.modules[module].childs[head].id}`, {
          })
            .then(response => {
              this.modules[module].childs.splice(head, 1)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Успешно',
                  icon: 'CheckIcon',
                  variant: 'success',
                  text: response.data.message,
                },
              })
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Ошибка',
                  icon: 'XCircleIcon',
                  variant: 'danger',
                  text: error.response.data.message,
                },
              })
            })
        } else if (typeChild === 2) {
          this.$http.post(`https://edu.startupchoikhona.tj/backend/api/lesson/delete/${this.modules[module].childs[head].id}`, {
          })
            .then(response => {
              this.modules[module].childs.splice(head, 1)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Успешно',
                  icon: 'CheckIcon',
                  variant: 'success',
                  text: response.data.message,
                },
              })
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Ошибка',
                  icon: 'XCircleIcon',
                  variant: 'danger',
                  text: error.response.data.message,
                },
              })
            })
        } else if (typeChild === 3) {
          this.$http.post(`https://edu.startupchoikhona.tj/backend/api/test/delete/${this.modules[module].childs[head].id}`, {
          })
            .then(response => {
              this.modules[module].childs.splice(head, 1)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Успешно',
                  icon: 'CheckIcon',
                  variant: 'success',
                  text: response.data.message,
                },
              })
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Ошибка',
                  icon: 'XCircleIcon',
                  variant: 'danger',
                  text: error.response.data.message,
                },
              })
            })
        }
      } else {
        this.$http.post(`https://edu.startupchoikhona.tj/backend/api/chapter/delete/${this.modules[module].childs[head].id}`, {
        })
          .then(response => {
            this.modules[module].childs.splice(head, 1)
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Успешно',
                icon: 'CheckIcon',
                variant: 'success',
                text: response.data.message,
              },
            })
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Ошибка',
                icon: 'XCircleIcon',
                variant: 'danger',
                text: error.response.data.message,
              },
            })
          })
      }
    },
    callLessonOrTest(module, head, lesson, type) {
      if (type === 'lesson') {
        this.showAddEditLessonSidebar(module, head, lesson)
      } else if (type === 'test') {
        this.showAddEditTestSidebar(module, head, lesson)
      }
    },
    showAddEditLessonSidebar(module, head, lesson) {
      this.isAddEditLessonSidebarActive = true
      this.selectedModule = module
      this.selectedHead = head
      this.selectedLesson = lesson
    },
    deleteLesson(module, head, lesson) {
      this.$http.post(`https://edu.startupchoikhona.tj/backend/api/lesson/delete/${this.modules[module].childs[head].lessons[lesson].id}`, {
      })
        .then(response => {
          this.modules[module].childs[head].lessons.splice(lesson, 1)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Успешно',
              icon: 'CheckIcon',
              variant: 'success',
              text: response.data.message,
            },
          })
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ошибка',
              icon: 'XCircleIcon',
              variant: 'danger',
              text: error.response.data.message,
            },
          })
        })
    },
    showAddEditTestSidebar(module, head, test) {
      this.isAddEditTestSidebarActive = true
      this.selectedModule = module
      this.selectedHead = head
      this.selectedTest = test
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.list-group-item {
  transition: all 1s
}
</style>
