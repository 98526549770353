<template>
  <div>
    <!-- Form: Personal Info Form -->
    <b-form class="mt-1">
      <b-row>
        <b-col md="12">
          <b-form-group
              label="Тип"
              label-for="type"
              label-class="mb-1"
          >
            <validation-provider
                #default="{ errors }"
                name="тип курса"
                rules="required"
            >
              <b-form-radio-group
                  v-model="type"
                  :options="typeOptions"
                  :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
              label="Наименование курса"
              label-for="title"
          >
            <validation-provider
                #default="{ errors }"
                name="Наименование курса"
                rules="required"
            >
              <b-form-input
                  id="email"
                  v-model="title"
                  type="text"
                  :state="errors.length > 0 ? false:null"
                  placeholder=""
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
              label="Фото"
              label-for="photo"
          >
            <b-form-file
                id="photo"
                v-model="photo"
                browse-text="Обзор..."
                accept="image/*"
                placeholder="Выберите файл или перетащите его сюда..."
                drop-placeholder="Скиньте файл сюда..."
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
              label="Описание"
              label-for="about"
          >
            <quill-editor
                id="about"
                v-model="about"
                :options="editorOption"
            />
          </b-form-group>
        </b-col>

      </b-row>
      <b-row class="mt-2">
        <b-col>
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="updateCourse"
          >
            Обновить
          </b-button>
          <b-button
            variant="outline-secondary"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="view"
          >
            Сбросить
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
  BFormInvalidFeedback,
  BFormCheckbox,
  BFormFile,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import ru from 'vee-validate/dist/locale/ru.json'
import flatPickr from 'vue-flatpickr-component'
import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import ImageResize from 'quill-image-resize-vue'
import * as Quill from 'quill'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


Quill.register('modules/imageResize', ImageResize)

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BFormInvalidFeedback,
    BFormCheckbox,
    BFormFile,
    quillEditor,
    ImageResize,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      type: 1,
      title: '',
      photo: null,
      about: '',
      typeOptions: [
        {
          text: 'Курс',
          value: 1,
        },
        {
          text: 'Тест',
          value: 2,
        },
      ],
      editorOption: {
        theme: 'snow',
        bounds: '#scrolling-container',
        scrollingContainer: '#scrolling-container',
        placeholder: 'Описание',
        modules: {
          imageResize: {},
          toolbar: {
            container: [
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              ['bold', 'italic', 'underline', 'strike'],
              [{ color: [] }, { background: [] }],
              [{ script: 'super' }, { script: 'sub' }],
              [{ header: '1' }, { header: '2' }, 'blockquote', 'code-block'],
              [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
              [{ direction: 'rtl' }, { align: [] }],
              ['link', 'image', 'video', 'formula'],
              ['clean'],
            ],
          },
        },
      },
      required,
    }
  },
  model: {
    prop: 'typeCourse',
    event: 'update:type-course',
  },
  props: {
    courseId: {
      type: [Number, String],
      required: true,
    },
    typeCourse: {
      type: Number,
      required: true,
    },
  },
  methods: {
    view() {
      this.$http.post(`https://edu.startupchoikhona.tj/backend/api/course/${this.courseId}/view`, {})
        .then(response => {
          const {
            data,
          } = response.data
          this.type = data.type
          this.$emit('update:type-course', data.type)

          this.title = data.title
          this.photo = null
          this.about = data.about
        }).catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ошибка',
              icon: 'XCircleIcon',
              variant: 'danger',
              text: error.data.message,
            },
          })
        })
    },
    updateCourse() {
      const data = {
        user_id: 1,
        title: this.title,
        type: this.type,
        about: this.about,
        image: this.photo,
      }
      const formData = new FormData()
      this.buildFormData(formData, data)
      this.$http.post(`https://edu.startupchoikhona.tj/backend/api/course/update/${this.courseId}`, formData)
        .then(response => {
          this.view()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Успешо',
              icon: 'CheckIcon',
              variant: 'success',
              text: response.data.message,
            },
          })
        }).catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ошибка',
              icon: 'XCircleIcon',
              variant: 'danger',
              text: error.data.message,
            },
          })
      })
    },
  },
  setup() {
    const buildFormData = (formData, data, parentKey) => {
      if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data)
            .forEach(key => {
              buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key)
            })
      } else {
        const value = data == null ? '' : data

        formData.append(parentKey, value)
      }
    }
    return {
      buildFormData,
    }
  },
  created() {
    localize('ru', ru)
    this.view()
  },
}
</script>

<style lang="scss">
.ql-editor {
  min-height: 300px;
}
</style>
