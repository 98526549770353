<template>
  <b-form>
    <course-final-test-add-new :final-tests.sync="questions" :test="test" :is-add-edit-test-sidebar-active.sync="isAddEditTestSidebarActive" @save-test="update"/>
    <b-row>
      <b-col
        cols="12"
        class="mb-2"
      >
        <h5 class="mb-0">
          Финальные тесты
        </h5>
        <small class="text-muted">Введите информацию о вашей финальной тест.</small>
      </b-col>

    </b-row>
    <b-form-group>
      <b-button
          variant="light"
          class="float-right"
          @click="showSidebar(-1)"
      >
        <span class="text-nowrap">Добавить вопрос</span>
      </b-button>
    </b-form-group>
    <b-row>
      <b-col
        cols="12"
        class=""
      >
        <b-table responsive="sm" :items="questions" :fields="fields">
          <template #cell(text)="data">
             <div v-html="data.item.text"></div>
          </template>
          <template #cell(options)="data">
             <div v-html="data.item.options.length"></div>
          </template>
          <template #cell(action)="data">
            <span class="d-none">{{data.index + 1}}</span>
            <span @click="showSidebar(data.index)">
              <feather-icon icon="EditIcon" class="text-primary mr-1 font-medium-5"/>
            </span>
            <span @click="deleteTest(data.index)">
              <feather-icon icon="Trash2Icon" class="text-danger mr-1 font-medium-5"/>
            </span>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row>

      <b-col class="mt-2 d-none">
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="update"
        >
          Обновить
        </b-button>
        <b-button
          variant="outline-secondary"
          @click="view"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          Сбросить
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BButton,
  BFormCheckbox,
  BTable,
} from 'bootstrap-vue'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import ImageResize from 'quill-image-resize-vue'
import * as Quill from 'quill'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import CourseFinalTestAddNew from '@/views/dashboard/courses/courses-edit/partials/CourseFinalTestAddNew.vue'

Quill.register('modules/imageResize', ImageResize)

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BButton,
    BFormCheckbox,
    BTable,
    quillEditor,
    ImageResize,
    CourseFinalTestAddNew,
  },
  props: {
    courseId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      tests: [],
      testsTable: [],
      questions: [],
      fields: [
        { key: 'text', label: 'Вопрос' },
        { key: 'options', label: 'Кол-во вариантов' },
        { key: 'action', label: 'Действие' },
      ],
      isAddEditTestSidebarActive: false,
      test: -1,
      finalTests: [],
      editorOption: {
        theme: 'snow',
        bounds: '#scrolling-container',
        scrollingContainer: '#scrolling-container',
        placeholder: 'Описание',
        modules: {
          imageResize: {},
          toolbar: {
            container: [
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              ['bold', 'italic', 'underline', 'strike'],
              [{ color: [] }, { background: [] }],
              [{ script: 'super' }, { script: 'sub' }],
              [{ header: '1' }, { header: '2' }, 'blockquote', 'code-block'],
              [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
              [{ direction: 'rtl' }, { align: [] }],
              ['link', 'image', 'video', 'formula'],
              ['clean'],
            ],
          },
        },
      },
    }
  },
  created() {
    this.view()
  },
  methods: {
    showSidebar(index) {
      this.isAddEditTestSidebarActive = true
      this.test = index
    },
    view() {
      this.$http.post(`https://edu.startupchoikhona.tj/backend/api/course/${this.courseId}/final-test`, {})
        .then(response => {
          const {
            data,
          } = response
          this.tests = data

          if (data.length > 0) {
            this.questions = data[0].questions
          }
          Array.from(this.questions, (value, index) => {
            this.testsTable.push({
              Вопрос: value.text,
              'Кол-во вариантов': value.options.length,
              Действие: index
            })
          })
        }).catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ошибка',
              icon: 'XCircleIcon',
              variant: 'danger',
              text: error.data.message,
            },
          })
        })
    },
    addTest() {
      this.questions.push({
        type: 'test',
        name: 'Тест',
        options: [],
      })
    },
    deleteTest(testIndex) {
      this.questions.splice(testIndex, 1)
      this.update()
    },
    addOption(testIndex) {
      this.questions[testIndex].options.push({
        text: '',
        is_correct: false,
      })
    },
    deleteOption(testIndex, optionIndex) {
      this.questions[testIndex].options.splice(optionIndex, 1)
    },
    update() {
      if (this.tests.length > 0) {
        this.$http.post('https://edu.startupchoikhona.tj/backend/api/test/update', {
          test: {
            id: this.tests[0].id,
            title: this.tests[0].name,
            course_id: this.courseId,
            questions: this.questions,
          },
        })
          .then(response => {
            this.view()
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Успешно',
                icon: 'CheckIcon',
                variant: 'success',
                text: response.data.message,
              },
            })
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Ошибка',
                icon: 'XCircleIcon',
                variant: 'danger',
                text: error.response.data.message,
              },
            })
          })
      } else {
        this.$http.post('https://edu.startupchoikhona.tj/backend/api/test/create', {
          test: {
            title: 'Финальный тест',
            course_id: this.courseId,
            questions: this.questions,
          },
        })
          .then(response => {
            this.view()
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Успешно',
                icon: 'CheckIcon',
                variant: 'success',
                text: response.data.message,
              },
            })
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Ошибка',
                icon: 'XCircleIcon',
                variant: 'danger',
                text: error.response.data.message,
              },
            })
          })
      }
    },
  },
}
</script>

<style>

</style>
