<template>
  <component :is="userData === undefined ? 'div' : 'b-card'">
    <b-tabs
      pills
    >

      <!-- Tab: Information -->
      <b-tab active>
        <template #title>
          <feather-icon
              icon="InfoIcon"
              size="16"
              class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Информация</span>
        </template>
        <course-edit-tab-information class="mt-2 pt-75" :course-id="id" :typeCourse.sync="typeCourse"/>
      </b-tab>

      <!-- Tab: Module -->
      <b-tab v-if="typeCourse === 1">
        <template #title>
          <feather-icon
            icon="BookIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Модули</span>
        </template>
        <course-edit-tab-modules
          :user-data="userData"
          :course-id="id"
          class="mt-2 pt-75"
        />
      </b-tab>

      <!-- Tab: Final Test -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="AwardIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Финальный тест</span>
        </template>
        <course-edit-tab-final-test class="mt-2 pt-75" :course-id="id"/>
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import CourseEditTabAccount from './CourseEditTabModules.vue'
import CourseEditTabInformation from './CourseEditTabInformation.vue'
import CourseEditTabFinalTest from './CourseEditTabFinalTest.vue'
import CourseEditTabModules from '@/views/dashboard/courses/courses-edit/CourseEditTabModules'

export default {
  components: {
    CourseEditTabModules,
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    CourseEditTabAccount,
    CourseEditTabInformation,
    CourseEditTabFinalTest,
  },
  data() {
    return {
      typeCourse: 1,
    }
  },
  setup() {
    const userData = ref(null)
    const id = router.currentRoute.params.id
    return {
      userData,
      id,
    }
  },
  created() {
  },
}
</script>

<style>

</style>
